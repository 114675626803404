// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../components/Heading/H2.res.js";
import * as H3 from "../../components/Heading/H3.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Portal from "../../components/Portal/Portal.res.js";
import * as Select from "../../forms/Select/Select.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Hooks from "@adeira/hooks";
import * as CreateModalLead from "../LeadModal/form/CreateModalLead.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PricingEstimatorMath from "./PricingEstimatorMath.res.js";
import BulletCheckSvg from "./assets/bullet-check.svg";
import * as PricingEstimatorModalScss from "./PricingEstimatorModal.scss";
import * as PricingEstimatorSliderCPUGroup from "./PricingEstimatorSliderCPUGroup.res.js";
import * as PricingEstimatorSliderRAMGroup from "./PricingEstimatorSliderRAMGroup.res.js";
import LogoDatacentersComSvg from "./assets/logo-datacenters.com.svg";
import * as PricingEstimatorSliderEgressGroup from "./PricingEstimatorSliderEgressGroup.res.js";

var css = PricingEstimatorModalScss;

var logoDatacenters = LogoDatacentersComSvg;

var iconBulletCheck = BulletCheckSvg;

var _map = {"per_month":"per_month","per_week":"per_week","per_day":"per_day","per_hour":"per_hour","per_minute":"per_minute","per_second":"per_second"};

function timeframeToJs(param) {
  return param;
}

function timeframeFromJs(param) {
  return _map[param];
}

function PricingEstimatorModal(props) {
  var scheduleDemoFormRef = React.useRef(null);
  var isIntersecting = Hooks.useIntersectionObserver(scheduleDemoFormRef);
  var match = React.useState(function () {
        return 0;
      });
  var setCpuMillicores = match[1];
  var cpuMillicores = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setRamMB = match$1[1];
  var ramMB = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setEgressGB = match$2[1];
  var egressGB = match$2[0];
  var match$3 = React.useState(function () {
        return "per_month";
      });
  var setTimeframe = match$3[1];
  var timeframe = match$3[0];
  var calculateEstimateTotalPerTimeframe = function (timeframe) {
    var cpuMillicores$1 = cpuMillicores;
    var ramMB$1 = ramMB;
    var egressGB$1 = egressGB;
    if (timeframe === "per_day") {
      return PricingEstimatorMath.calculateEstimatedTotalPerDay(cpuMillicores$1, ramMB$1, egressGB$1);
    } else if (timeframe === "per_hour") {
      return PricingEstimatorMath.calculateEstimatedTotalPerHour(cpuMillicores$1, ramMB$1, egressGB$1);
    } else if (timeframe === "per_week") {
      return PricingEstimatorMath.calculateEstimatedTotalPerWeek(cpuMillicores$1, ramMB$1, egressGB$1);
    } else if (timeframe === "per_minute") {
      return PricingEstimatorMath.calculateEstimatedTotalPerMinute(cpuMillicores$1, ramMB$1, egressGB$1);
    } else if (timeframe === "per_second") {
      return PricingEstimatorMath.calculateEstimatedTotalPerSecond(cpuMillicores$1, ramMB$1, egressGB$1);
    } else {
      return PricingEstimatorMath.calculateEstimatedTotalPerMonth(cpuMillicores$1, ramMB$1, egressGB$1);
    }
  };
  var bulletStyle = {
    listStyleImage: "url(" + iconBulletCheck + ")"
  };
  return JsxRuntime.jsx(Portal.make, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("img", {
                                          src: logoDatacenters
                                        }),
                                    JsxRuntime.jsx(Button.make, {
                                          size: "MD",
                                          color: "Clear",
                                          className: css.button,
                                          onClick: props.onClose,
                                          children: JsxRuntime.jsx(IconClose.make, {
                                                size: "MD",
                                                color: "DarkerGray"
                                              })
                                        })
                                  ],
                                  className: css.modalHeader
                                }),
                            className: css.modalHeaderWrapper
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(H2.make, {
                                            children: "Pricing Estimator"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Submit the application to schedule a demo and learn more about the multicloud solution.",
                                            className: css.pricingEstimatorDescription
                                          }),
                                      JsxRuntime.jsx(PricingEstimatorSliderCPUGroup.make, {
                                            onValueChange: (function (value) {
                                                setCpuMillicores(function (param) {
                                                      return value;
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(PricingEstimatorSliderRAMGroup.make, {
                                            onValueChange: (function (value) {
                                                setRamMB(function (param) {
                                                      return value;
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(PricingEstimatorSliderEgressGroup.make, {
                                            onValueChange: (function (value) {
                                                setEgressGB(function (param) {
                                                      return value;
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(H2.make, {
                                            children: "What's Included"
                                          }),
                                      JsxRuntime.jsxs("ul", {
                                            children: [
                                              JsxRuntime.jsx("li", {
                                                    children: "NAT Gateways",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Internet Gateways",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "TLS certificates",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "DNS and global DNS",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Secrets storage and management",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Container registry storage and management",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Log storage and consolidation",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Metrics",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Load balancing",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Audit trail",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Container orchestration (e.g., K8s)",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Service mesh (e.g., Istio)",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "VPN between VPCs",
                                                    style: bulletStyle
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: "Auto-Scaling",
                                                    style: bulletStyle
                                                  })
                                            ]
                                          })
                                    ],
                                    className: css.startColumn
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(H3.make, {
                                                    children: "Estimated Total Per Location"
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "$",
                                                      PricingEstimatorMath.format(calculateEstimateTotalPerTimeframe(timeframe))
                                                    ],
                                                    className: css.estimatedTotalPrice
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsxs(Select.make, {
                                                          id: "estimatedTotalDuration",
                                                          value: timeframe,
                                                          className: css.estimatedTotalDuration,
                                                          onChange: (function ($$event) {
                                                              setTimeframe($$event.target.value);
                                                            }),
                                                          children: [
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_month",
                                                                  children: "Per month"
                                                                }),
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_week",
                                                                  children: "Per week"
                                                                }),
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_day",
                                                                  children: "Per day"
                                                                }),
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_hour",
                                                                  children: "Per hour"
                                                                }),
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_minute",
                                                                  children: "Per minute"
                                                                }),
                                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                                  value: "per_second",
                                                                  children: "Per second"
                                                                })
                                                          ]
                                                        })
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: "No contracts, minimums, or lock-ins",
                                                    className: css.estimatedTotalNoCommitmentTitle
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: "A typical workload costs less than $5 per month per location.",
                                                    className: css.estimatedTotalNoCommitmentSubtitle
                                                  })
                                            ],
                                            className: css.estimatedTotal
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    id: "schedule_a_demo"
                                                  }),
                                              JsxRuntime.jsx(H3.make, {
                                                    children: "Schedule a Demo"
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: "Submit the form to schedule a demo and learn more about the multicloud solution.",
                                                    className: css.scheduleDemoDescription
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx(CreateModalLead.make, {
                                                          buttonTitle: "Schedule Demo",
                                                          leadType: "Multicloud"
                                                        }),
                                                    ref: Caml_option.some(scheduleDemoFormRef),
                                                    className: css.scheduleDemoForm
                                                  })
                                            ],
                                            className: css.scheduleDemo
                                          })
                                    ],
                                    className: css.endColumn
                                  })
                            ],
                            className: css.modalBody
                          }),
                      isIntersecting === false ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("span", {
                                                    children: [
                                                      "$",
                                                      PricingEstimatorMath.format(calculateEstimateTotalPerTimeframe(timeframe))
                                                    ],
                                                    className: css.estimatedTotalPrice
                                                  }),
                                              "/",
                                              timeframe === "per_day" ? "day" : (
                                                  timeframe === "per_hour" ? "hour" : (
                                                      timeframe === "per_week" ? "week" : (
                                                          timeframe === "per_minute" ? "minute" : (
                                                              timeframe === "per_second" ? "second" : "month"
                                                            )
                                                        )
                                                    )
                                                )
                                            ]
                                          }),
                                      JsxRuntime.jsx(Button.AsLink.make, {
                                            href: "#schedule_a_demo",
                                            size: "MD",
                                            color: "Primary",
                                            children: "Schedule Demo"
                                          })
                                    ],
                                    className: Cx.cx([
                                          "container",
                                          css.modalFooter
                                        ])
                                  }),
                              className: css.modalFooterWrapper
                            }) : null
                    ],
                    className: css.modalRoot
                  })
            });
}

var make = PricingEstimatorModal;

export {
  css ,
  logoDatacenters ,
  iconBulletCheck ,
  timeframeToJs ,
  timeframeFromJs ,
  make ,
}
/* css Not a pure module */
