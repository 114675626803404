// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../Link/A.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SubNavigationScss from "./SubNavigation.scss";

var css = SubNavigationScss;

function SubNavigation$Link(props) {
  return JsxRuntime.jsx(A.make, {
              href: props.href,
              className: css.link,
              children: props.text
            });
}

var Link = {
  make: SubNavigation$Link
};

function SubNavigation(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("strong", {
                            children: props.title
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: css.wrapperEnd
                    })
              ],
              className: css.wrapper
            });
}

var make = SubNavigation;

export {
  css ,
  Link ,
  make ,
}
/* css Not a pure module */
