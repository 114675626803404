// Generated by ReScript, PLEASE EDIT WITH CARE

import RcSlider from "rc-slider";
import * as SliderScss from "./Slider.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as IndexCss from "rc-slider/assets/index.css";

var css = SliderScss;

function Slider(props) {
  return JsxRuntime.jsx(RcSlider, {
              min: props.min,
              max: props.max,
              value: props.value,
              onChange: props.onChange,
              className: css.sliderBase
            });
}

function Slider$Range(props) {
  return JsxRuntime.jsx(RcSlider, {
              min: props.min,
              max: props.max,
              onChange: props.onChange,
              onAfterChange: props.onAfterChange,
              value: props.value,
              defaultValue: props.defaultValue,
              allowCross: props.allowCross,
              className: css.sliderRangeBase
            });
}

var $$Range = {
  make: Slider$Range
};

var make = Slider;

export {
  css ,
  make ,
  $$Range ,
}
/* css Not a pure module */
