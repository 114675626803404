// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import IconCpuSvg from "../assets/icon-cpu.svg";
import IconRamSvg from "../assets/icon-ram.svg";
import IconTrafficSvg from "../assets/icon-traffic.svg";
import * as SimplePricingMulticloudScss from "./SimplePricingMulticloud.scss";

var css = SimplePricingMulticloudScss;

var iconCpu = IconCpuSvg;

var iconRam = IconRamSvg;

var iconTraffic = IconTrafficSvg;

function SimplePricingMulticloud(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Simple pricing",
                      className: css.subtitle
                    }),
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: "Only Pay for CPU, RAM and Egress"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("img", {
                                      src: iconCpu
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "CPU: vCPU-second",
                                              className: css.title
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "$0.0000000236136",
                                              className: css.price
                                            })
                                      ],
                                      className: css.priceRectangleText
                                    })
                              ],
                              className: css.priceRectangle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("img", {
                                      src: iconRam
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "RAM: GiB-second",
                                              className: css.title
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "$0.0000025632",
                                              className: css.price
                                            })
                                      ],
                                      className: css.priceRectangleText
                                    })
                              ],
                              className: css.priceRectangle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("img", {
                                      src: iconTraffic
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Egress Traffic: GB",
                                              className: css.title
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "$0.12",
                                              className: css.price
                                            })
                                      ],
                                      className: css.priceRectangleText
                                    })
                              ],
                              className: css.priceRectangle
                            })
                      ],
                      className: css.priceRectanglesRow
                    })
              ],
              className: css.wrapper
            });
}

var make = SimplePricingMulticloud;

export {
  css ,
  iconCpu ,
  iconRam ,
  iconTraffic ,
  make ,
}
/* css Not a pure module */
