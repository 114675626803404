// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Html from "../../../../../libs/Html.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as UnderscoreFancy from "../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancy.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FreeMonthsExplainer from "../free-months-explainer/FreeMonthsExplainer.res.js";
import * as ScheduleModalServices from "../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as DeployNowButtonMulticloud from "../deploy-now-button/DeployNowButtonMulticloud.res.js";
import * as JumbotronMulticloudScss from "./JumbotronMulticloud.scss";

var css = JumbotronMulticloudScss;

function JumbotronMulticloud(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.heading,
                      children: "Multicloud Deployment to Any Cloud, Anywhere, Anytime"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(DeployNowButtonMulticloud.make, {
                              isUserLoggedIn: props.isUserLoggedIn,
                              userData: props.userData,
                              setUserData: props.setUserData,
                              size: "MD",
                              expanded: true
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Clear",
                              ghost: true,
                              expanded: true,
                              onClick: (function (_event) {
                                  setIsScheduleDemoModalOpen(function (param) {
                                        return true;
                                      });
                                }),
                              children: "Schedule a Demo"
                            })
                      ],
                      className: css.buttonsRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Deploy to AWS, Google Cloud and Microsoft Azure in seconds and get",
                        JsxRuntime.jsxs(UnderscoreFancy.make, {
                              children: [
                                Html.nbsp,
                                JsxRuntime.jsx(FreeMonthsExplainer.make, {})
                              ],
                              color: "Teal"
                            })
                      ],
                      className: css.freeMonthsMessage
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Multicloud Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about multicloud solutions.",
                        buttonTitle: "Submit",
                        leadType: "Multicloud"
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = JumbotronMulticloud;

export {
  css ,
  make ,
}
/* css Not a pure module */
