// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../components/Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PricingEstimatorSliderGroupScss from "./PricingEstimatorSliderGroup.scss";

var css = PricingEstimatorSliderGroupScss;

function PricingEstimatorSliderGroup(props) {
  var iconSvg = props.iconSvg;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("img", {
                      className: Cx.cx([
                            css.icon,
                            css.iconDesktop
                          ]),
                      src: iconSvg
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("img", {
                                      className: Cx.cx([
                                            css.icon,
                                            css.iconMobile
                                          ]),
                                      src: iconSvg
                                    }),
                                JsxRuntime.jsx(H3.make, {
                                      children: props.title
                                    })
                              ],
                              className: css.pricingHeading
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.description
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.pricingDescription,
                              className: css.pricingDescription
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.sliderComponent
                            })
                      ],
                      className: css.pricing
                    })
              ],
              className: css.sliderGroupWrapper
            });
}

var make = PricingEstimatorSliderGroup;

export {
  css ,
  make ,
}
/* css Not a pure module */
