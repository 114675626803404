// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Html from "../../../libs/Html.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import IconTrafficSvg from "./assets/icon-traffic.svg";
import * as PricingEstimatorSliderGroup from "./PricingEstimatorSliderGroup.res.js";
import * as PricingEstimatorSliderInput from "./PricingEstimatorSliderInput.res.js";

var iconTraffic = IconTrafficSvg;

function PricingEstimatorSliderEgressGroup(props) {
  var onValueChange = props.onValueChange;
  var match = React.useState(function () {
        return true;
      });
  var setUseGB = match[1];
  var useGB = match[0];
  return JsxRuntime.jsx(PricingEstimatorSliderGroup.make, {
              title: "Egress Traffic",
              description: "Egress traffic represents the amount of data that is sent to the internet from your workloads.",
              pricingDescription: "$" + Html.nbsp + "0.12 per GB per month",
              iconSvg: iconTraffic,
              sliderComponent: JsxRuntime.jsx(PricingEstimatorSliderInput.make, {
                    minValue: useGB ? 0 : 1,
                    maxValue: 1000,
                    onValueChange: (function (value) {
                        onValueChange(useGB ? value : Math.imul(value, 1000));
                      }),
                    onToggle: (function (param) {
                        setUseGB(function (prevValue) {
                              return !prevValue;
                            });
                      }),
                    buttons: [
                      {
                        title: "GB",
                        isActive: useGB === true
                      },
                      {
                        title: "TB",
                        isActive: useGB === false
                      }
                    ]
                  }, useGB ? "_1" : "_2")
            });
}

var make = PricingEstimatorSliderEgressGroup;

export {
  iconTraffic ,
  make ,
}
/* iconTraffic Not a pure module */
