// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Slider from "../../forms/Slider/Slider.res.js";
import * as ButtonGroup from "../../components/Button/ButtonGroup.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PricingEstimatorSliderInputScss from "./PricingEstimatorSliderInput.scss";

var css = PricingEstimatorSliderInputScss;

function PricingEstimatorSliderInput(props) {
  var onValueChange = props.onValueChange;
  var minValue = props.minValue;
  var match = React.useState(function () {
        return minValue;
      });
  var setSelectedValue = match[1];
  var selectedValue = match[0];
  React.useEffect((function () {
          onValueChange(minValue);
        }), []);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Slider.make, {
                            min: minValue,
                            max: props.maxValue,
                            value: selectedValue,
                            onChange: (function (value) {
                                setSelectedValue(function (param) {
                                      return value;
                                    });
                                onValueChange(value);
                              })
                          }),
                      className: css.slider
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("input", {
                            className: css.inputField,
                            type: "number",
                            value: selectedValue.toString(),
                            onChange: (function ($$event) {
                                var value = $$event.target.value;
                                setSelectedValue(function (param) {
                                      return value;
                                    });
                                onValueChange(value);
                              })
                          }),
                      className: css.input
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ButtonGroup.make, {
                            buttons: props.buttons,
                            onToggle: props.onToggle
                          }),
                      className: css.buttonGroup
                    })
              ],
              className: css.wrapper
            });
}

var make = PricingEstimatorSliderInput;

export {
  css ,
  make ,
}
/* css Not a pure module */
