// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../schedule-modal-services/ScheduleModalServices.res.js";
import * as BannerSignUpTodayScss from "./BannerSignUpToday.scss";

var css = BannerSignUpTodayScss;

function BannerSignUpToday(props) {
  var deployNowButtonComponent = props.deployNowButtonComponent;
  var match = React.useState(function () {
        return false;
      });
  var setIsModalOpen = match[1];
  var tmp;
  if (match[0]) {
    switch (props.bannerType) {
      case "BareMetalCloud" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule a Demo",
                subtitle: "Submit the form to schedule a demo and learn more about bare metal solutions.",
                buttonTitle: "Submit",
                leadType: "BareMetal"
              });
          break;
      case "ZenlayerBareMetal" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule a Zenlayer Demo",
                subtitle: "Submit the form to schedule a demo and learn more about Zenlayer bare metal solutions.",
                buttonTitle: "Submit",
                leadType: "ZenlayerBareMetal"
              });
          break;
      case "EnzuBareMetal" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule an Enzu Demo",
                subtitle: "Submit the form to schedule a demo and learn more about Enzu bare metal solutions.",
                buttonTitle: "Submit",
                leadType: "EnzuBareMetal"
              });
          break;
      case "HivelocityBareMetal" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule a Hivelocity Demo",
                subtitle: "Submit the form to schedule a demo and learn more about Hivelocity bare metal solutions.",
                buttonTitle: "Submit",
                leadType: "HivelocityBareMetal"
              });
          break;
      case "EvocativeBareMetal" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule an Evocative Demo",
                subtitle: "Submit the form to schedule a demo and learn more about Evocative bare metal solutions.",
                buttonTitle: "Submit",
                leadType: "EvocativeBareMetal"
              });
          break;
      case "Multicloud" :
          tmp = JsxRuntime.jsx(ScheduleModalServices.make, {
                onClose: (function () {
                    setIsModalOpen(function (param) {
                          return false;
                        });
                  }),
                title: "Schedule a Multicloud Demo",
                subtitle: "Submit the form to schedule a demo and learn more about multicloud solutions.",
                buttonTitle: "Submit",
                leadType: "Multicloud"
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: props.title
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        deployNowButtonComponent !== undefined ? Caml_option.valFromOption(deployNowButtonComponent) : null,
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Clear",
                              ghost: true,
                              className: css.button,
                              onClick: (function (_event) {
                                  setIsModalOpen(function (param) {
                                        return true;
                                      });
                                }),
                              children: "Schedule a Demo"
                            })
                      ],
                      className: css.buttonsRow
                    }),
                tmp
              ],
              className: css.root
            });
}

var make = BannerSignUpToday;

export {
  css ,
  make ,
}
/* css Not a pure module */
