// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as BannerCaseStudy from "../../../common/banner-case-study/BannerCaseStudy.res.js";
import * as Routes_Resource from "../../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as NotifyMulticloudCaseStudyDownloadRequest from "../../../../../api/requests/NotifyMulticloudCaseStudyDownloadRequest.res.js";

var initialState = {
  signInModal: "Hidden"
};

function reducer(_state, action) {
  return {
          signInModal: action._0
        };
}

function CaseStudyMulticloud(props) {
  var userLoginStatus = props.userLoginStatus;
  var multicloudCaseStudy = props.multicloudCaseStudy;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0].signInModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BannerCaseStudy.make, {
                      title: "Multicloud Case Study",
                      description: "We at Datacenters.com are using this technology and it saves us resources and guarantees the best performance to our customers all over the world.",
                      ctaComponent: multicloudCaseStudy !== undefined ? JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  if (userLoginStatus === "LoggedIn") {
                                    return $$Promise.wait(NotifyMulticloudCaseStudyDownloadRequest.exec(ID.toString(multicloudCaseStudy.id)), (function (x) {
                                                  if (x.TAG !== "Ok") {
                                                    return SentryLogger.error1({
                                                                rootModule: "CaseStudyMulticloud",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "CaseStudyMulticloud.make"
                                                              }, "CaseStudyMulticloud" + "::CaseStudyMulticloud::notifyDownload::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                  Url.visitBlank(multicloudCaseStudy.privateDownload);
                                                  Url.visit(Routes_Resource.downloadThanks(multicloudCaseStudy.title));
                                                }));
                                  } else {
                                    return dispatch({
                                                TAG: "ToggleSignInModal",
                                                _0: "Shown"
                                              });
                                  }
                                }),
                              children: "Download Case Study"
                            }) : null,
                      testimonialText: "\"Cloud cost is always a consideration, but the indirect costs were our real concern - the cost of redoing work and having key developers spend 20% of their time on DevOps. Control Plane allows us to optimize both direct and indirect cloud costs.\"",
                      testimonialAuthorName: "Jim Nasr",
                      testimonialAuthorRole: "CEO, Acoer",
                      testimonialAuthorImage: Cloudinary.imageUrl("v1663189349/1624287038763")
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to download the case study"
                      }) : null
              ]
            });
}

var make = CaseStudyMulticloud;

export {
  initialState ,
  reducer ,
  make ,
}
/* ID Not a pure module */
