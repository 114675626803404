// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../models/User.res.js";
import * as FAQMulticloud from "./components/faqs/FAQMulticloud.res.js";
import * as WhyMulticloud from "./components/why-multicloud/WhyMulticloud.res.js";
import * as MulticloudScss from "./Multicloud.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcons from "../../../styleguide/components/BannerProviderIcons/BannerProviderIcons.res.js";
import * as CaseStudyMulticloud from "./components/case-study/CaseStudyMulticloud.res.js";
import * as JumbotronMulticloud from "./components/jumbotron/JumbotronMulticloud.res.js";
import * as CloudThatLovesYouBack from "./components/cloud-that-loves-you-back/CloudThatLovesYouBack.res.js";
import * as ServicesSeparatorBlock from "../ServicesSeparatorBlock.res.js";
import * as SimplePricingMulticloud from "./components/simple-pricing/SimplePricingMulticloud.res.js";
import * as SubNavigationMulticloud from "./components/sub-navigation/SubNavigationMulticloud.res.js";
import * as RequestEstimateMulticloud from "./components/request-estimate/RequestEstimateMulticloud.res.js";
import * as LearnMoreSectionMulticloud from "./components/learn-more/LearnMoreSectionMulticloud.res.js";
import * as BannerSignUpTodayMulticloud from "./components/sign-up-today/BannerSignUpTodayMulticloud.res.js";
import HeaderDotsSvg from "../common/assets/header-dots.svg";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";
import MulticloudHeaderDotsSvg from "./components/assets/multicloud-header-dots.svg";

var css = MulticloudScss;

var headerDotsSvg = HeaderDotsSvg;

var multicloudHeaderSvg = MulticloudHeaderDotsSvg;

function Multicloud$default(props) {
  var userData = props.userData;
  var setUserData = props.setUserData;
  var isUserLoggedIn = props.isUserLoggedIn;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronMulticloud.make, {
                                  isUserLoggedIn: isUserLoggedIn,
                                  userData: userData,
                                  setUserData: setUserData
                                }),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + multicloudHeaderSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(BannerProviderIcons.make, {
                            context: "Multicloud"
                          }),
                      className: css.providerIconsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(SubNavigationMulticloud.make, {
                            isUserLoggedIn: isUserLoggedIn,
                            userData: userData,
                            setUserData: setUserData
                          }),
                      className: css.subNavigationSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CloudThatLovesYouBack.make, {}),
                      className: css.cloudThatLovesYouBackSection
                    }),
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {}),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_what_is"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(LearnMoreSectionMulticloud.make, {}),
                            className: css.wideContainer
                          }),
                      className: css.learnMoreSection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_pricing"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(SimplePricingMulticloud.make, {}),
                      className: css.simplePricingSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(RequestEstimateMulticloud.make, {}),
                      className: css.requestEstimateMulticloudSection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_why"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(WhyMulticloud.make, {}),
                      className: css.whyMulticloudSection
                    }),
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {}),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_locations"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ComponentsImportsLoadableJsx.MulticloudMap, {})
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_case_study"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CaseStudyMulticloud.make, {
                            multicloudCaseStudy: props.multicloudCaseStudy,
                            userLoginStatus: User.LoginStatus.fromBool(isUserLoggedIn),
                            setUserData: setUserData
                          })
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "multicloud_faq"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(FAQMulticloud.make, {
                            isUserLoggedIn: isUserLoggedIn,
                            userData: userData,
                            setUserData: setUserData
                          }),
                      className: css.faqSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(BannerSignUpTodayMulticloud.make, {
                                  isUserLoggedIn: isUserLoggedIn,
                                  userData: userData,
                                  setUserData: setUserData
                                }),
                            style: {
                              backgroundImage: "url(" + headerDotsSvg + ")",
                              backgroundSize: "cover"
                            }
                          }),
                      className: css.signUpTodaySection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = Multicloud$default;

export {
  css ,
  headerDotsSvg ,
  multicloudHeaderSvg ,
  $$default as default,
}
/* css Not a pure module */
