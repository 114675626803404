// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Testimonial from "../../../../styleguide/components/Testimonial/Testimonial.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerCaseStudyScss from "./BannerCaseStudy.scss";
import TestimonialDatacentersComSvg from "./assets/testimonial-datacenters.com.svg";

var css = BannerCaseStudyScss;

var testimonialBackground = TestimonialDatacentersComSvg;

function BannerCaseStudy(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.wrapperBackgroundImage,
                      style: {
                        backgroundImage: "url(" + testimonialBackground + ")"
                      }
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H2.make, {
                                      className: css.title,
                                      children: props.title
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.description,
                                      className: css.description
                                    }),
                                props.ctaComponent
                              ],
                              className: css.ctaWrapper
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Testimonial.make, {
                                    name: props.testimonialAuthorName,
                                    role: props.testimonialAuthorRole,
                                    description: props.testimonialText,
                                    imageUrl: props.testimonialAuthorImage,
                                    classNameText: css.testimonialText,
                                    classNameAuthor: css.testimonialText,
                                    showMore: false
                                  }),
                              className: css.testimonialWrapper
                            })
                      ],
                      className: css.wrapperContainer
                    })
              ],
              className: css.wrapper
            });
}

var make = BannerCaseStudy;

export {
  css ,
  testimonialBackground ,
  make ,
}
/* css Not a pure module */
