// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Html from "../../../libs/Html.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import IconCpuSvg from "./assets/icon-cpu.svg";
import * as PricingEstimatorSliderGroup from "./PricingEstimatorSliderGroup.res.js";
import * as PricingEstimatorSliderInput from "./PricingEstimatorSliderInput.res.js";

var iconCPU = IconCpuSvg;

function PricingEstimatorSliderCPUGroup(props) {
  var onValueChange = props.onValueChange;
  var match = React.useState(function () {
        return true;
      });
  var setUseMillicores = match[1];
  var useMillicores = match[0];
  return JsxRuntime.jsx(PricingEstimatorSliderGroup.make, {
              title: "CPU",
              description: "Capacity AI allocates a continuously optimized number of vCPU millicores (1/1000 of a vCPU) and RAM to guarantee a significant cost saving.",
              pricingDescription: "$" + Html.nbsp + "0.0000000236136 per vCPU-second per millicore",
              iconSvg: iconCPU,
              sliderComponent: JsxRuntime.jsx(PricingEstimatorSliderInput.make, {
                    minValue: useMillicores ? 25 : 1,
                    maxValue: useMillicores ? 1000 : 64,
                    onValueChange: (function (value) {
                        onValueChange(useMillicores ? value : Math.imul(value, 1000));
                      }),
                    onToggle: (function (param) {
                        setUseMillicores(function (prevValue) {
                              return !prevValue;
                            });
                      }),
                    buttons: [
                      {
                        title: "Millicores",
                        isActive: useMillicores === true
                      },
                      {
                        title: "Cores",
                        isActive: useMillicores === false
                      }
                    ]
                  }, useMillicores ? "_1" : "_2")
            });
}

var make = PricingEstimatorSliderCPUGroup;

export {
  iconCPU ,
  make ,
}
/* iconCPU Not a pure module */
