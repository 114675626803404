// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SubNavigation from "../../../../../styleguide/components/SubNavigation/SubNavigation.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DeployNowButtonMulticloud from "../deploy-now-button/DeployNowButtonMulticloud.res.js";

function SubNavigationMulticloud(props) {
  return JsxRuntime.jsxs(SubNavigation.make, {
              title: "Multicloud",
              children: [
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_what_is",
                      text: "What is Multicloud?"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_pricing",
                      text: "Pricing"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_why",
                      text: "Why Multicloud?"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_locations",
                      text: "Locations"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_case_study",
                      text: "Case Study"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#multicloud_faq",
                      text: "FAQ"
                    }),
                JsxRuntime.jsx(DeployNowButtonMulticloud.make, {
                      isUserLoggedIn: props.isUserLoggedIn,
                      userData: props.userData,
                      setUserData: props.setUserData,
                      size: "SM",
                      expanded: false
                    })
              ]
            });
}

var make = SubNavigationMulticloud;

export {
  make ,
}
/* SubNavigation Not a pure module */
