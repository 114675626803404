// Generated by ReScript, PLEASE EDIT WITH CARE


function calculatePriceCPU(cpuMillicores) {
  return Math.max(cpuMillicores, 0.0) * 0.0000000236136;
}

function calculatePriceRAM(ramMB) {
  return Math.max(ramMB, 0.0) / 1000.0 * 0.0000025632;
}

function calculatePriceEgressTraffic(egressGB) {
  return Math.max(egressGB, 0.0) * 0.12 / 2592000.0;
}

function calculateEstimatedTotalPerSecond(cpuMillicores, ramMB, egressGB) {
  return calculatePriceCPU(cpuMillicores) + calculatePriceRAM(ramMB) + calculatePriceEgressTraffic(egressGB);
}

function calculateEstimatedTotalPerMinute(cpuMillicores, ramMB, egressGB) {
  return calculateEstimatedTotalPerSecond(cpuMillicores, ramMB, egressGB) * 60.0;
}

function calculateEstimatedTotalPerHour(cpuMillicores, ramMB, egressGB) {
  return calculateEstimatedTotalPerMinute(cpuMillicores, ramMB, egressGB) * 60.0;
}

function calculateEstimatedTotalPerDay(cpuMillicores, ramMB, egressGB) {
  return calculateEstimatedTotalPerHour(cpuMillicores, ramMB, egressGB) * 24.0;
}

function calculateEstimatedTotalPerWeek(cpuMillicores, ramMB, egressGB) {
  return calculateEstimatedTotalPerDay(cpuMillicores, ramMB, egressGB) * 7.0;
}

function calculateEstimatedTotalPerMonth(cpuMillicores, ramMB, egressGB) {
  return calculateEstimatedTotalPerDay(cpuMillicores, ramMB, egressGB) * 30.0;
}

function format(number) {
  if (number !== 0.0) {
    if (number > 1.0) {
      return number.toFixed(2);
    } else {
      return number.toFixed(8);
    }
  } else {
    return "0.0";
  }
}

var priceCPU = 0.0000000236136;

var priceRAM = 0.0000025632;

var priceEgressTraffic = 0.12;

export {
  priceCPU ,
  priceRAM ,
  priceEgressTraffic ,
  calculatePriceCPU ,
  calculatePriceRAM ,
  calculatePriceEgressTraffic ,
  calculateEstimatedTotalPerSecond ,
  calculateEstimatedTotalPerMinute ,
  calculateEstimatedTotalPerHour ,
  calculateEstimatedTotalPerDay ,
  calculateEstimatedTotalPerWeek ,
  calculateEstimatedTotalPerMonth ,
  format ,
}
/* No side effect */
