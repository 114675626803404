// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../Heading/H3.res.js";
import * as H4 from "../Heading/H4.res.js";
import * as LazyImage from "../LazyImage/LazyImage.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerLearnMoreScss from "./BannerLearnMore.scss";

var css = BannerLearnMoreScss;

function BannerLearnMore(props) {
  var ctaComponent = props.ctaComponent;
  var isReversed = props.isReversed;
  var __subtitle = props.subtitle;
  var subtitle = __subtitle !== undefined ? __subtitle : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.subtitle,
                              children: subtitle
                            }),
                        JsxRuntime.jsx(H3.make, {
                              className: css.title,
                              children: props.title
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.description,
                              className: css.description
                            }),
                        ctaComponent !== undefined ? JsxRuntime.jsx("div", {
                                children: Caml_option.valFromOption(ctaComponent),
                                className: css.buttonRow
                              }) : null
                      ],
                      className: Cx.cx([
                            css.bannerSection,
                            isReversed ? css.textRight : css.textLeft
                          ])
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LazyImage.make, {
                            src: props.svgIllustration,
                            height: 420,
                            offset: 100,
                            className: css.svgIllustration
                          }),
                      className: Cx.cx([
                            css.bannerSection,
                            isReversed ? css.imageLeft : css.imageRight
                          ])
                    })
              ],
              className: Cx.cx([
                    css.bannerWrapperBase,
                    isReversed ? css.bannerWrapperReversed : css.bannerWrapper
                  ])
            });
}

var make = BannerLearnMore;

export {
  css ,
  make ,
}
/* css Not a pure module */
