// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BannerSignUpToday from "../../../common/banner-sign-up/BannerSignUpToday.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FreeMonthsExplainer from "../free-months-explainer/FreeMonthsExplainer.res.js";
import * as DeployNowButtonMulticloud from "../deploy-now-button/DeployNowButtonMulticloud.res.js";

function BannerSignUpTodayMulticloud(props) {
  return JsxRuntime.jsx(BannerSignUpToday.make, {
              title: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      "Get ",
                      JsxRuntime.jsx(FreeMonthsExplainer.make, {}),
                      " When You Sign up Today!"
                    ]
                  }),
              bannerType: "Multicloud",
              deployNowButtonComponent: Caml_option.some(JsxRuntime.jsx(DeployNowButtonMulticloud.make, {
                        isUserLoggedIn: props.isUserLoggedIn,
                        userData: props.userData,
                        setUserData: props.setUserData,
                        size: "MD",
                        expanded: false
                      }))
            });
}

var make = BannerSignUpTodayMulticloud;

export {
  make ,
}
/* BannerSignUpToday Not a pure module */
