// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "./Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ButtonGroupScss from "./ButtonGroup.scss";

var css = ButtonGroupScss;

function ButtonGroup(props) {
  var className = props.className;
  var __size = props.size;
  var __isActiveColor = props.isActiveColor;
  var onToggle = props.onToggle;
  var isActiveColor = __isActiveColor !== undefined ? __isActiveColor : "Teal";
  var size = __size !== undefined ? __size : "XS";
  return JsxRuntime.jsx("div", {
              children: Belt_Array.mapWithIndex(props.buttons, (function (index, button) {
                      return JsxRuntime.jsx(Button.make, {
                                  size: size,
                                  color: button.isActive ? isActiveColor : "White",
                                  className: Cx.cx([
                                        css.button,
                                        index === 0 ? css.buttonLeft : css.buttonRight,
                                        button.isActive ? "" : (
                                            isActiveColor === "DarkerGray" ? css.buttonInactiveDarkerGray : css.buttonInactive
                                          )
                                      ]),
                                  onClick: (function ($$event) {
                                      if (button.isActive === false) {
                                        return onToggle($$event);
                                      }
                                      
                                    }),
                                  children: button.title
                                }, button.title);
                    })),
              className: className !== undefined ? Cx.cx([
                      css.wrapper,
                      className
                    ]) : css.wrapper
            });
}

var make = ButtonGroup;

export {
  css ,
  make ,
}
/* css Not a pure module */
