// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BannerReasons from "../../../../../styleguide/components/BannerReasons/BannerReasons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerSingleReason from "../../../../../styleguide/components/BannerReasons/BannerSingleReason.res.js";
import IconSecureSvg from "../assets/icon-secure.svg";
import IconPerformanceSvg from "../assets/icon-performance.svg";
import IconRepatriationSvg from "../assets/icon-repatriation.svg";

var iconRepatriation = IconRepatriationSvg;

var iconPerformance = IconPerformanceSvg;

var iconSecure = IconSecureSvg;

function CloudThatLovesYouBack(props) {
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(BannerReasons.make, {
                        title: "Cloud that Loves You Back",
                        children: [
                          JsxRuntime.jsx(BannerSingleReason.make, {
                                title: "No to Cloud Repatriation",
                                description: "Regain control of escalating cloud costs.",
                                svgIcon: iconRepatriation
                              }),
                          JsxRuntime.jsx(BannerSingleReason.make, {
                                title: "High Performance",
                                description: "Fine tune control of CPU and Memory backed by AI.",
                                svgIcon: iconPerformance
                              }),
                          JsxRuntime.jsx(BannerSingleReason.make, {
                                title: "Secure the Cloud",
                                description: "Securely consume services inside VPCs and on-prem private networks.",
                                svgIcon: iconSecure
                              })
                        ]
                      }))
            });
}

var make = CloudThatLovesYouBack;

export {
  iconRepatriation ,
  iconPerformance ,
  iconSecure ,
  make ,
}
/* iconRepatriation Not a pure module */
