// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Html from "../../../libs/Html.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import IconRamSvg from "./assets/icon-ram.svg";
import * as PricingEstimatorSliderGroup from "./PricingEstimatorSliderGroup.res.js";
import * as PricingEstimatorSliderInput from "./PricingEstimatorSliderInput.res.js";

var iconRAM = IconRamSvg;

function PricingEstimatorSliderRAMGroup(props) {
  var onValueChange = props.onValueChange;
  var match = React.useState(function () {
        return true;
      });
  var setUseMB = match[1];
  var useMB = match[0];
  return JsxRuntime.jsx(PricingEstimatorSliderGroup.make, {
              title: "RAM",
              description: "Capacity AI reduces RAM consumption by approx. 10X, achieving significant cost savings.",
              pricingDescription: "$" + Html.nbsp + "0.000002136 per GiB-second",
              iconSvg: iconRAM,
              sliderComponent: JsxRuntime.jsx(PricingEstimatorSliderInput.make, {
                    minValue: useMB ? 12 : 1,
                    maxValue: useMB ? 1000 : 512,
                    onValueChange: (function (value) {
                        onValueChange(useMB ? value : Math.imul(value, 1000));
                      }),
                    onToggle: (function (param) {
                        setUseMB(function (prevValue) {
                              return !prevValue;
                            });
                      }),
                    buttons: [
                      {
                        title: "MB",
                        isActive: useMB === true
                      },
                      {
                        title: "GB",
                        isActive: useMB === false
                      }
                    ]
                  }, useMB ? "_1" : "_2")
            });
}

var make = PricingEstimatorSliderRAMGroup;

export {
  iconRAM ,
  make ,
}
/* iconRAM Not a pure module */
