// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../Heading/H3.res.js";
import * as LazyImage from "../LazyImage/LazyImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerSingleReasonScss from "./BannerSingleReason.scss";

var css = BannerSingleReasonScss;

function BannerSingleReason(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LazyImage.make, {
                      src: props.svgIcon,
                      height: 60,
                      offset: 100,
                      className: css.svgIcon
                    }),
                JsxRuntime.jsx(H3.make, {
                      className: css.reasonTitle,
                      children: props.title
                    }),
                JsxRuntime.jsx("div", {
                      children: props.description,
                      className: css.reasonDescription
                    })
              ],
              className: css.reason
            });
}

var make = BannerSingleReason;

export {
  css ,
  make ,
}
/* css Not a pure module */
