// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerReasons from "../../../../../styleguide/components/BannerReasons/BannerReasons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerSingleReason from "../../../../../styleguide/components/BannerReasons/BannerSingleReason.res.js";
import IconOpsNoPainSvg from "../assets/icon-ops-no-pain.svg";
import IconMultiRegionSvg from "../assets/icon-multi-region.svg";
import IconMicroservicesSvg from "../assets/icon-microservices.svg";
import IconVirtualCloudSvg from "../assets/icon-virtual-cloud.svg";

var iconOpsNoPain = IconOpsNoPainSvg;

var iconVirtualCloud = IconVirtualCloudSvg;

var iconMultiRegion = IconMultiRegionSvg;

var iconMicroservices = IconMicroservicesSvg;

function WhyMulticloud(props) {
  return JsxRuntime.jsxs(BannerReasons.make, {
              title: "Why Multicloud?",
              description: "Virtual Cloud for compute - a PaaS (platform-as-a-service) that empowers developers to run microservices on multiple clouds and regions.",
              children: [
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Ops without the Pain",
                      description: "Best in class Ops, codified, using tools you already know and trust.",
                      svgIcon: iconOpsNoPain
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Virtual Cloud",
                      description: "Mix-n-match services from AWS/Azure/GCP in a single workload.",
                      svgIcon: iconVirtualCloud
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Multi-Region",
                      description: "Ultra-low latency and unbreakable 99.999% availability.",
                      svgIcon: iconMultiRegion
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Unbreakable Microservices",
                      description: "Regional or global cloud outage? No problem! The platform intelligently routes to alternative healthy clouds/regions.",
                      svgIcon: iconMicroservices
                    })
              ]
            });
}

var make = WhyMulticloud;

export {
  iconOpsNoPain ,
  iconVirtualCloud ,
  iconMultiRegion ,
  iconMicroservices ,
  make ,
}
/* iconOpsNoPain Not a pure module */
