// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as UnderscoreFancy from "../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancy.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FreeMonthsExplainer from "../free-months-explainer/FreeMonthsExplainer.res.js";
import * as BannerRequestEstimate from "../../../common/banner-request-estimate/BannerRequestEstimate.res.js";
import * as PricingEstimatorModal from "../../../../../styleguide/modals/PricingEstimatorModal/PricingEstimatorModal.res.js";

function RequestEstimateMulticloud(props) {
  var match = React.useState(function () {
        return "Closed";
      });
  var setPricingEstimatorState = match[1];
  var tmp;
  tmp = match[0] === "Opened" ? JsxRuntime.jsx(PricingEstimatorModal.make, {
          onClose: (function (param) {
              setPricingEstimatorState(function (param) {
                    return "Closed";
                  });
            })
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BannerRequestEstimate.make, {
                      title: "One plan - simple. Use our multicloud pricing estimator to make a predictable budget for your deployment.",
                      buttonTitle: "Pricing Estimator",
                      buttonSubtitleComponent: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              "Get ",
                              JsxRuntime.jsx(UnderscoreFancy.make, {
                                    children: JsxRuntime.jsx(FreeMonthsExplainer.make, {}),
                                    color: "Teal"
                                  })
                            ]
                          }),
                      onButtonClick: (function (param) {
                          setPricingEstimatorState(function (param) {
                                return "Opened";
                              });
                        }),
                      color: "Primary"
                    }),
                tmp
              ]
            });
}

var make = RequestEstimateMulticloud;

export {
  make ,
}
/* react Not a pure module */
