// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";

function exec(resourceId) {
  return Rest.$$fetch("/resources/" + (resourceId + "/notify-multicloud-case-study-download"), {
              NAME: "Post",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
