// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Env from "../../../../../libs/Env.res.js";
import * as Faq from "../../../../../styleguide/components/Faq/Faq.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Page from "../../../../../routes/common/Routes_Page.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FAQMulticloudScss from "./FAQMulticloud.scss";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ScheduleModalServices from "../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as ControlPlaneRedirectModal from "../redirect-modal/ControlPlaneRedirectModal.res.js";

var css = FAQMulticloudScss;

function FAQMulticloud(props) {
  var userData = props.userData;
  var isUserLoggedIn = props.isUserLoggedIn;
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsControlPlaneRedirectModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsLoginModalOpen = match$2[1];
  var redirectToControlPlane = function (redirectUri) {
    if (redirectUri !== undefined) {
      return Url.visit(redirectUri);
    } else if (Env.controlPlaneRedirectLoginUrl !== undefined) {
      return Url.visit(Env.controlPlaneRedirectLoginUrl);
    } else {
      return ;
    }
  };
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.heading,
                      children: "Learn More About Multicloud"
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What multicloud services are available?",
                      answer: "The platform currently offers Amazon Web Services, Microsoft Azure, and Google Cloud Platform. You can mix-and-match all of the services, across multiple regions to create a seamless multicloud deployment."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How do I sign up and get started?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Click ",
                                  JsxRuntime.jsx("text", {
                                        children: "here",
                                        className: css.answerWithLink,
                                        onClick: (function (_event) {
                                            if (isUserLoggedIn && userData !== undefined) {
                                              var match = userData.controlPlaneAccountStatus;
                                              var exit = 0;
                                              switch (match) {
                                                case "Active" :
                                                    return redirectToControlPlane(undefined);
                                                case "InProgress" :
                                                case "Inactive" :
                                                    exit = 2;
                                                    break;
                                                
                                              }
                                              if (exit === 2) {
                                                return setIsControlPlaneRedirectModalOpen(function (param) {
                                                            return true;
                                                          });
                                              }
                                              
                                            }
                                            setIsLoginModalOpen(function (param) {
                                                  return true;
                                                });
                                          })
                                      }),
                                  " to register for 2 months free. Once registered, a team member will reach out to you to learn more about your project and offer any advice you need."
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What regions of the world are served?",
                      answer: "You can deploy to multiple cloud platforms anywhere in the world offered by Amazon AWS, Microsoft Azure and Google Cloud Platform (GCP). You can deploy to one or more regions with a simple click of the button. Replicas are created, deployed and managed by the platform. Demand is routed automatically reducing latency."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How does it work with Kubernetes?",
                      answer: "While Kubernetes orchestrates workloads within a single cluster, the platform orchestrates an unlimited number of hardened, security-isolated Kubernetes clusters in all the regions of the major clouds. Rather than standing up clusters and dealing with the complexity of K8s, the platform enables developers to focus solely on the 'dev' side and not the 'ops' side. The platform completely alleviates the developers' need to deal with or have in-depth knowledge of Kubernetes, Istio, TLS, DNS geo-routing, load balancing, secrets management, logging, metrics, scaling up/down, and many day-2, ops-related concerns associated with running apps & microservices in production."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "Why should I use the multicloud platform?",
                      answer: "When running backend apps in production, the ops-related activities are monumental - regardless of whether you run a single line of code or ten million. Rather than invest a substantial portion of the cost, time, and energy on the above non-business-value-adding aspects of your system, the platform frees you to focus on the development part, where your unique effort adds real value."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How does the multicloud platform improve our user experience?",
                      answer: "The platform utilizes advanced, redundant health monitoring and geographically-distributed DNS infrastructure. It automatically re-routes traffic to healthy regions and clusters. It removes unhealthy and unreachable nodes from rotation, so the end-users measured availability and latency are optimal. A user from one part of the world experiences ultra-low-latency responses, while a user on the other side of the planet experiences similar ~20-30 ms latency."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "Why should I work with Datacenters.com for my multicloud deployment?",
                      answer: "Our users get 2 months free when they sign up! Datacenters.com has access to a network of experts who can help you plan and manage your multicloud deployment. In addition, your multicloud services will be linked together with colocation, Internet, network and other cloud needs through our unique CloudRack™ Control Center. Mange all of your services in one convenient place."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How much control will I have of the multicloud deployment?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "The platform provides advanced, consistent, yet easy-to-use fine-grained authorization controls. These controls are identical whether administering ",
                                  JsxRuntime.jsx(Link.make, {
                                        href: Routes_Page.controlPlaneSignUp,
                                        children: "Control Plane"
                                      }),
                                  " itself or your custom workloads. This unique capability sets the platform apart from other cloud or third-party solutions on the market. Your workloads get an out-of-the-box fine-grained authorization \"dial tone\" that uniformly handles simple and complex use cases alike."
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "I'm not ready to deploy. How can I schedule a demo?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Just click ",
                                  JsxRuntime.jsx("text", {
                                        children: "here",
                                        className: css.answerWithLink,
                                        onClick: (function (_event) {
                                            setIsScheduleDemoModalOpen(function (param) {
                                                  return true;
                                                });
                                          })
                                      }),
                                  ", complete the form and our Concierge team will set up a time with you."
                                ]
                              }))
                    }),
                userData !== undefined && match$1[0] ? JsxRuntime.jsx(ControlPlaneRedirectModal.make, {
                        onClose: (function (param) {
                            setIsControlPlaneRedirectModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        onSignup: redirectToControlPlane
                      }) : null,
                match$2[0] ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        callback: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                            setIsControlPlaneRedirectModalOpen(function (param) {
                                  return true;
                                });
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to start deploying your CloudRack™️ services today!"
                      }) : null,
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Multicloud Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about multicloud solutions.",
                        buttonTitle: "Submit",
                        leadType: "Multicloud"
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = FAQMulticloud;

export {
  css ,
  make ,
}
/* css Not a pure module */
